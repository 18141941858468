import React from 'react'
import Layout from '../components/layout'

export default function muchAdoNews1() {
    return (
        <Layout>
            <div style={{padding: "108px 20px 20px 10px", width: 400, margin: "0 auto"}}>
                <h1>The Scandalous Crier</h1>

                <h2>Claudio's Lament</h2>

                <p>Heartfelt apology or empty words? Does it matter after her death? 
“I'm deeply sorry for the hurt and embarrassment I've caused to those close to Hero and everyone this has affected. This series of events and my callous response caused me to lose the most important thing in my life, the person I love and respect the most, Hero. I love her, I love her, I'm so sorry.”
</p>
            </div>
        </Layout>
    )
}